<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
    <div class="col-12 col-sm-12">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Actions -->
              <div class="flexbox px-4 py-3 mb-3">
                <div class="d-flex align-items-center">
                </div>
                <div class="btn-group" role="group" aria-label="false">
                  <router-link :to="{name: 'MailTemplateNew'}" class="btn btn-outline-light  pull-right">
                    Novo
                  </router-link>
                </div>
              </div>
              <!-- End Actions -->

              <div class="table-responsive ">
                <vuetable ref="vuetable"
                          :noDataTemplate="this.$i18n.t('mails.template.empty')"
                          :api-url="templateEndpoint"
                          :fields="vTableFields"
                          :css="css.table"
                          pagination-path="pagination"
                          :per-page="20"
                          :http-options="httpOptions"
                          track-by="id_template"
                          @vuetable:pagination-data="onPaginationData"
                          @vuetable:loading="onLoading"
                          @vuetable:loaded="onLoaded"
                          @vuetable:load-error="handleLoadError"
                          @vuetable:cell-clicked="onRowClicked">
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link btn-padding" :title="$t('actions.edit')"
                          v-bind:href="mountLink('MailTemplateEdit', props.rowData)">
                        <i class="text-primary  ti-pencil font-20"></i>
                      </a>
                      <a class="btn btn-link btn-padding" :title="$t('actions.delete')"
                          v-on:click="onDelete(props.rowData)">
                        <i class="text-primary  ti-trash font-20"></i>
                      </a>
                    </div>
                  </template>
                </vuetable>
              </div>
              <vuetable-pagination ref="pagination"
                                    :css="css.pagination"
                                    @vuetable-pagination:change-page="onChangePage"
              ></vuetable-pagination>
              <!-- END Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import MailService from '@/services/MailService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'mailTemplate',
  data () {
    return {
      isLoading: false,
      fullPage: true,
      template: [],
      totalPages: 0,
      templateEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/mailtemplate',
      fieldsTable: [
        {
          name: 'name',
          title: this.$i18n.t('mails.template.name'),
          sortField: 'name'
        },
        {
          name: 'sender.nome',
          title: this.$i18n.t('mails.template.sender'),
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'created_by.email',
          title: this.$i18n.t('form.task.created_by'),
          sortField: 'createdBy_email',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatUser(value)
        },
        {
          name: 'show_signature',
          title: this.$i18n.t('mails.template.show_signature'),
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatBoolean(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    Vuetable,
    VuetablePagination,
    // MailService
    Loading
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
    // this.showTemplates()
  },
  methods: {
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ name: 'MailTemplateEdit', params: { id_template: row.data.id_template } })
    },
    handleLoadError (response) {
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_template: object.id_template } }).href
    },
    // Delete
    onDelete (template) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta tarefa.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(template)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Tarefa excluída com sucesso!')
        }
      })
    },
    async delete (template) {
      let _this = this
      _this.isLoading = true

      await MailService.deleteTemplate(template.id_template).then(template => {
        _this.isLoading = false
        _this.$refs.vuetable.refresh()
      })
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .text {
    /* background-color: #4CAF50; */
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    content: "\e64e";
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>
